<template>
  <v-breadcrumbs
    v-if="breadcrumbList && $vuetify.breakpoint.mdAndUp"
    :items="breadcrumbList"
  >
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        active-class=""
        :to="item.to"
        :disabled="item.disabled"
      >
        <template v-if="item.text === 'dynamic_crumb'">
          <div :style="light ? 'color: #fff' : 'color: #eee'">
            {{
              crumb ? crumb.toString().toUpperCase() : $tc("pages.carregando")
            }}
          </div>
        </template>
        <template v-else-if="item.text === 'pages.home'">
          <div :style="light ? 'color: #fff' : 'color: #eee'">
            <v-icon class="ml-2">home</v-icon>
            {{
              $tc(item.text)
                .toString()
                .toUpperCase()
            }}
          </div>
        </template>
        <template v-else>
          <div :style="light ? 'color: #fff' : 'color: #eee'">
            <template v-if="item.plural">
              {{
                $tc(item.text, 2)
                  .toString()
                  .toUpperCase()
              }}
            </template>
            <template v-else>
              {{
                $tc(item.text)
                  .toString()
                  .toUpperCase()
              }}
            </template>
          </div>
        </template>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BreadCrumbs",
  data: () => ({
    breadcrumbList: [],
  }),
  watch: {
    $route() {
      this.updateList();
    },
  },
  computed: {
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
      crumb: (state) => state.dynamic_crumb,
    }),
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  mounted() {
    this.updateList();
  },
};
</script>

<style lang="scss" scoped>
.v-breadcrumbs {
  li {
    ::v-deep div.v-breadcrumbs__item {
      opacity: 0.4;
    }
    ::v-deep .v-breadcrumbs__item div {
      max-width: 200px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: ltr;
    }
  }
}
</style>
